<script setup lang="ts">
import { vScroll } from '@vueuse/components'
import type { UseScrollReturn } from '@vueuse/core'
import {
  breakpointsTailwind,
  onClickOutside,
  useElementBounding,
  useResizeObserver,
} from '@vueuse/core'
import { computed, ref, useRoute, useRouter } from '#imports'
import { useLayoutState } from '../composables/layout'
import RialticLogo from './RialticLogo.vue'

const isOpen = defineModel<boolean>()

const props = defineProps<{
  hasUpdate?: boolean
  hideNavDrawer?: boolean
  mini?: boolean
  version?: string
}>()

const emit = defineEmits<{
  update: []
}>()

const root = ref(null)
const toolbarRef = ref<Element | null>(null)

const {
  detailsPanelWidth,
  isScrolling,
  scrollEl,
  sideNavMini: isMini,
  viewWidth,
} = useLayoutState({ sideNavMini: !!props.mini })
const { height: toolbarHeight } = useElementBounding(toolbarRef)

const isDetailView = computed(() => !!useRoute().meta.isDetailView)

const mainGridTemplate = computed(() => {
  if (!isDetailView.value || viewWidth.value < breakpointsTailwind.lg)
    return '100% 0'

  return `calc(100% - ${detailsPanelWidth.value}px) ${detailsPanelWidth.value}px`
})

const toggleMini = (mini?: boolean) => {
  isMini.value = mini != null ? mini : !isMini.value
}

useRouter().afterEach((to, _from) => {
  if (to.meta?.miniNav) isMini.value = true

  if (viewWidth.value < breakpointsTailwind.lg) isOpen.value = false
})

const target = ref(null)
onClickOutside(target, () => {
  if (viewWidth.value < breakpointsTailwind.lg) isOpen.value = false
})

const onScroll = (state: UseScrollReturn) => {
  isScrolling.value = state.isScrolling
}

useResizeObserver(root, (entries) => {
  const entry = entries[0]
  const { width } = entry.contentRect

  viewWidth.value = width

  if (width < breakpointsTailwind.lg) isOpen.value = false
})
</script>

<template>
  <div
    ref="root"
    class="flex min-h-screen w-full flex-col pl-0"
    :class="{
      'lg:pl-64': !isMini && !hideNavDrawer,
      'lg:pl-18': isMini && !hideNavDrawer,
    }"
  >
    <aside
      ref="target"
      class="bg-primary fixed left-0 top-0 z-50 h-full w-64 transform-gpu shadow transition-transform lg:transform-none"
      :class="{
        '-translate-x-64 ease-out': !isOpen,
        'translate-x-0 ease-in': isOpen,
        'lg:max-w-64': !isMini,
        'lg:max-w-18': isMini,
        'pointer-events-none -translate-x-64 opacity-0': hideNavDrawer,
      }"
    >
      <div
        class="pb-13 flex h-full w-full flex-col items-start justify-start space-y-8 overflow-hidden py-2"
      >
        <div
          class="pl-4.5 flex h-12 w-3/4 items-end justify-end space-x-3 pr-3 pt-8"
        >
          <NuxtLink
            to="/"
            class="inline-block w-full transform transition-transform"
            :class="{ '-translate-x-[6px] scale-90': isMini }"
            title="Home"
          >
            <!-- TODO: handle isMini when mobile -->
            <slot v-bind="{ isMini }" name="logo">
              <RialticLogo :icon="isMini" />
            </slot>
          </NuxtLink>
        </div>
        <div class="w-full flex-1 overflow-y-auto">
          <slot v-bind="{ isMini, open: isOpen, toggleMini }" name="nav" />
        </div>
      </div>
      <footer
        class="bg-primary duration pointer-events-none fixed bottom-0 left-0 min-h-12 w-64 w-full overflow-hidden px-3 py-2 transition ease-out"
        :class="{ 'max-w-18': isMini, 'max-w-64': !isMini }"
      >
        <button
          v-if="!isMini && hasUpdate"
          class="btn btn-text pointer-events-auto absolute p-2 text-neutral-200"
          :class="{ 'opacity-0': isMini }"
          type="button"
          @click="emit('update')"
        >
          <i class="i-ph-arrow-circle-up-bold inline-block" />
          <span>Refresh to update</span>
        </button>
        <span
          v-else-if="version"
          class="absolute py-2 pl-4 text-sm text-neutral-300"
          :class="{ 'opacity-0': isMini }"
        >
          {{ version }}
        </span>
        <button
          class="pointer-events-auto hidden transform-gpu rounded-full p-2 text-white transition-transform lg:block"
          :class="{
            'translate-x-1.5 rotate-180': isMini,
            'translate-x-48 rotate-0': !isMini,
          }"
          aria-label="minimize nav drawer"
          type="button"
          data-analytics="nav-toggle-width"
          @click="toggleMini()"
        >
          <div class="i-ph-caret-left-bold h-5 w-5" />
        </button>
      </footer>
    </aside>

    <div ref="toolbarRef">
      <slot v-bind="{ isMini, open: isOpen }" name="toolbar" />
    </div>

    <div
      class="grid w-full transition-all"
      :style="{
        'grid-template-columns': mainGridTemplate,
      }"
    >
      <div
        ref="scrollEl"
        v-scroll="onScroll"
        class="flex w-full flex-1 flex-col overflow-y-auto"
        :style="`height: calc(100vh - ${toolbarHeight}px)`"
      >
        <slot v-bind="{ isMini, open: isOpen }" />
      </div>

      <div id="details_panel_target" ref="detailsPanel" />
    </div>
  </div>
</template>

<style scoped>
aside,
footer {
  transition-property: transform, max-width;
}
</style>
